<template>
    <div class="amher">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane name="first" label="广告管理">
                <!-- 表格内容 -->
                <div class="avatar_table">
                    <el-card>
                        <!-- 添加 删除 下拉框 按钮部分 -->
                        <div class="out_sel_btn">
                            <div class="sel_inp">
                                <el-input placeholder="请输入广告名称" prefix-icon="el-icon-search" v-model="search_inp"
                                    clearable size="mini" @change="initData">
                                </el-input>
                            </div>
                            <div class="add_del_btn">
                                <el-button type="primary" class="addBtn" @click="handlePoster">新建</el-button>
                            </div>
                        </div>
                        <!-- 内容表格部分 -->
                        <div class="table_init">
                            <el-table :data="tableData" tooltip-effect="dark" style="width: 100%"
                                :header-cell-style="{color:'#333333',background:'#FAFAFA'}">
                                <el-table-column prop="uid" label="ID" v-if="show=false">
                                </el-table-column>
                                <el-table-column prop="ad_name" label="广告名称">
                                </el-table-column>
                                <el-table-column prop="launch_time" label="投放类型">
                                </el-table-column>
                                <el-table-column prop="created_at" label="广告发布日期">
                                </el-table-column>
                                <el-table-column prop="price_method" label="定价方式">
                                </el-table-column>
                                <el-table-column label="启用">
                                    <template slot-scope="scope">
                                        <el-switch v-model="scope.row.status" active-color="#13ce66"
                                            inactive-color="#D4D4D4" @change="handle_state(scope.row.uid)">
                                        </el-switch>
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作">
                                    <template slot-scope="scope">
                                        <span style="margin-right:16px" @click="handleEdit(scope.row.uid)">编辑</span>
                                        <span @click="handle_Delet(scope.row.uid)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <!-- 分页部分 -->
                        <Paging :count="count" :initData="initData" @changePage="handle_changePage"></Paging>
                    </el-card>
                </div>
            </el-tab-pane>
            <el-tab-pane name="second" label="数据展示">
                <!--日期选择区域 -->
                <div class="date_sel">
                    <!-- 日期部分 -->
                    <div class="dateDiv">
                        <span>日期范围</span>
                        <el-date-picker v-model="start_date" type="date" placeholder="选择日期" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd" size="mini" style="margin-right:6px">
                        </el-date-picker>--
                        <el-date-picker v-model="end_date" type="date" placeholder="选择日期" format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd" size="mini" style="margin-left:6px" @change="date_change">
                        </el-date-picker>
                    </div>
                    <!-- 下拉框选择广告部分 -->
                    <div class="selDiv">
                        <span>广告名称</span>
                        <el-select v-model="poster_id" placeholder="请选择广告" clearable size="mini" @change="sel_change">
                            <el-option v-for="item in poster_arr" :key="item.uid" :label="item.ad_name"
                                :value="item.uid">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 图形数据区域 -->
                <div class="photoDiv">
                    <el-tabs v-model="activeName1" @tab-click="handleClick">
                        <el-tab-pane name="first" label="可视图表">
                            <!-- 统计图区域 -->
                            <div style="width:950px;height:400px;margin-bottom:30px" ref="chart"></div>
                        </el-tab-pane>
                        <el-tab-pane name="second" label="数据列表">
                            <el-table :data="tableData1" tooltip-effect="dark" style="width: 100%"
                                :header-cell-style="{color:'#000000',background:'#FAFAFA'}">
                                <el-table-column prop="day" label="日期">
                                </el-table-column>
                                <el-table-column prop="visit_count" label="访问量">
                                </el-table-column>
                                <el-table-column prop="hit_count" label="点击量">
                                </el-table-column>
                                <el-table-column prop="hit_rate" label="点击率">
                                </el-table-column>
                            </el-table>
                            <Paging :count="se_count" :initData="initData2" @changePage="se_changePage"></Paging>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </el-tab-pane>
        </el-tabs>
        <!-- 弹框部分 -->
        <!-- 删除弹框 -->
        <el-dialog :visible.sync="del_dialog" width="567px" :before-close="handleClose" @close="remove_Fn">
            <!-- 标题 -->
            <div class="tipes">
                <div class="sd"></div>
                <div class="ax">删除</div>
            </div>
            <div class="del_content">确定删除此条数据？</div>
            <!-- 提交 取消 按钮 -->
            <div class="formBtn">
                <el-button type="primary" class="del_confirm" @click="handle_del">确定</el-button>
                <el-button class="del_Reset" @click="remove_Fn">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/Paging';
import { getPosterApi, statePosterApi, removePosterApi, dataPosterApi } from '@/api'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            // 登录用户id
            user_id: '',
            // 选项卡
            activeName: 'first',
            // 弹框显示隐藏
            del_dialog: false,    //删除弹框
            // 删除数据的数组
            del_arr: [],
            // 每条数据id
            id: '',
            //  表格数据
            tableData: [],
            // 分页--------------------------
            page: 1,
            count: 0,
            max: 10,
            // 搜索输入框
            search_inp: '',
            // 数据展示 开始日期
            start_date: '',
            // 数据展示  结束日期
            end_date: '',
            // 下拉  广告id
            poster_id: '',
            poster_arr: [],
            // 下级选项卡
            activeName1: 'first',
            // 数据展示  表格数据
            tableData1: [],
            // 数据展示分页
            se_page: 1,
            se_count: 0,
            se_max: 10,
            // 统计图数据  x轴日期
            date_arr: [],
            // 统计图数据  y轴最大值
            num_max: '',
            // 统计图数据  y轴步进
            num_step: '',
            // 统计图数据  点击量数据
            hit_arr: [],
            // 统计图数据  访问量数据
            visit_arr: [],
        }
    },
    created () {
        this.initData()
		// this.initData2()
    },
    watch: {
        // 监听广告管理分页数量的变化
        count () {
            if (this.count == (this.page - 1) * this.max && this.count != 0) {
                this.page -= 1;
                this.initData()
            }
        },
        // 监听浏览量分页数量的变化
        se_count () {
            if (this.se_count == (this.se_page - 1) * this.se_max && this.se_count != 0) {
                this.se_page -= 2;
                this.initData2()
            }
        },
    },
    methods: {
        // 选项卡点击
        handleClick () {
            if (this.activeName == 'first') {
                this.initData();
            } else if (this.activeName == 'second') {
                if (this.activeName1 == 'first') {
                    this.initData1();
                } else if (this.activeName1 == 'second') {
                    this.initData2();
                }
            }
        },
        // 子组件传过来的当前页数  广告数据
        handle_changePage (data) {
            this.page = data;
        },
        // 子组件传过来的当前页数   数据展示数据
        se_changePage (data) {
			console.log(data);
            this.se_page = data;
        },
        // 初始化数据  广告数据
        initData () {
            this.user_id = sessionStorage.getItem('id');
            getPosterApi({
                page: this.page,   //当前页
                ad_name: this.search_inp,  //广告名称
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.tableData = res.data;
                    this.count = res.count;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        //统计图数据  数据初始化
        initData1 () {
            dataPosterApi({
                ad_id: this.poster_id,   //广告id
                date_start: this.start_date,  //开始日期
                date_end: this.end_date,  //结束日期
                auth_userid: this.user_id,  //登录用户id
                type: 1
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.poster_arr = res.adver;
                    this.date_arr = res.date;
                    this.visit_arr = res.visit;
                    this.hit_arr = res.hit;
                    this.num_max = res.max;
                    this.num_step = res.step;
                    this.initCharts();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 
        initData2 () {
            dataPosterApi({
                ad_id: this.poster_id,   //广告id
                date_start: this.start_date,  //开始日期
                date_end: this.end_date,  //结束日期
                auth_userid: this.user_id,  //登录用户id
                type: 2,
				page: this.se_page
            }).then(res => {
                console.log(res)
                if (res.status.code == 200) {
                    this.poster_arr = res.adver;
                    this.tableData1 = res.data;
					this.se_count = res.count;
					// this.poster_arr = res.adver;
					// this.date_arr = res.date;
					// this.visit_arr = res.visit;
					// this.hit_arr = res.hit;
					// this.num_max = res.max;
					// this.num_step = res.step;
					// this.initCharts();
					
					
					
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 首页状态改变
        handle_state (id) {
            let arr = [];
            arr.push(id);
            statePosterApi({
                data_uid: arr,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    arr.length = 0;
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 日期选择变化  数据展示
        date_change () {
            if (this.activeName1 == 'first') {
                this.initData1();
            } else if (this.activeName1 == 'second') {
                this.initData2();
            }
        },
        // 下拉框选择变化  数据展示
        sel_change () {
            if (this.activeName1 == 'first') {
                this.initData1();
            } else if (this.activeName1 == 'second') {
                this.initData2();
            }
        },
        // 统计图数据
        initCharts () {
            let myChart = this.$echarts.init(this.$refs.chart);
            // console.log(this.$refs.chart)
            // 绘制图表
            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: ['点击量', '访问量'],
                    left: '5%'
                },
                color: ['#FFCF1E', '#6FCF97'],
                grid: {
                    left: '3%',
                    right: '2%',
                    bottom: '3%',
                    containLabel: true,
                    width: '93%'
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: this.date_arr,
                        // splitLine: {
                        //     show: true,
                        // },
                        // axisLabel: {//设置数据距离y轴的距离
                        //     margin: 100
                        // },:
                        // offset: 30,
                        axisLine: { //关键设置，不显示X轴线条
                            show: false
                        },
                        axisTick: { //关键设置，坐标轴刻度也不显示
                            show: false,
                        },
                        axisLabel: {
                            interval: 0,  //x轴具体刻度显示
                            showMaxLabel: true,
                            rotate: 30
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        min: 0,
                        max: this.num_max,
                        interval: this.num_step,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                        offset: 20,
                        // nameTextStyle: {
                        //     align: left
                        // },
                        axisLine: { //关键设置，不显示y轴线条
                            show: false
                        },
                        // axisTick: { //关键设置，坐标轴刻度也不显示
                        //     show: false
                        // },
                    }
                ],
                series: [
                    {
                        name: '点击量',
                        type: 'line',
                        stack: '总量',
                        areaStyle: {
                            color: '#FFCF1E'
                        },
                        lineStyle: {
                            color: '#FFCF1E'
                        },
                        itemStyle: {
                            normal: {
                                label: {//每个点具体数值的显示
                                    show: true,
                                    textStyle: {
                                        color: '#000000'
                                    }
                                }
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.hit_arr
                    },
                    {
                        name: '访问量',
                        type: 'line',
                        stack: '总量',
                        areaStyle: {
                            color: '#6FCF97'
                        },
                        lineStyle: {
                            color: '#6FCF97'
                        },
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#333333'
                                    }
                                }
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: this.visit_arr
                    }
                ]
            });
        },
        // 新建广告跳转
        handlePoster () {
            this.$router.push({ path: '/built', query: { id: '新建广告' } })
        },
        // 点击编辑按钮跳转
        handleEdit (id) {
            this.$router.push({ path: '/built', query: { id: id } })
        },
        // 点击单个删除按钮
        handle_Delet (id) {
            this.id = id;
            this.del_dialog = true;
            this.del_arr.push(id);
        },
        remove_Fn () {
            this.del_dialog = false;
            this.del_arr.splice(0, this.del_arr.length)
        },
        // 删除弹框点击  提交 按钮
        handle_del () {
            removePosterApi({
                id: this.del_arr,
                auth_userid: this.user_id,  //登录用户id
            }).then(res => {
                // console.log(res)
                if (res.status.code == 200) {
                    this.$message.success('删除成功');
                    this.del_dialog = false;
                    this.initData();
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                } else if (res.status.code == 202) {
                    this.$message.error('登录过期，请重新登录！');
                    this.$router.push({ path: '/login' })
                }
            })
        },
        // 弹窗关闭
        handleClose () {
            this.del_dialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.amher {
    width: 100%;
    height: 100%;
    display: flex;
    // 选项卡
    .el-tabs {
        width: 100%;
        ::v-deep.el-tabs__active-bar {
            display: none;
        }
        ::v-deep.el-tabs__item {
            width: 112px;
            height: 40px;
            text-align: center;
            color: #525252;
            font-size: 18px;
            font-weight: 900;
        }
        ::v-deep.el-tabs__item:hover {
            color: #333333;
            font-size: 18px;
            font-weight: 900;
        }
        ::v-deep.el-tabs__item.is-active {
            width: 112px;
            height: 40px;
            // text-align: center;
            border-radius: 24px;
            background: #2479dd;
            color: #ffffff;
            font-weight: 500;
        }
        ::v-deep#tab-first {
            text-align: right;
        }
        ::v-deep#tab-second {
            text-align: left;
        }
        ::v-deep.el-tabs__nav-wrap::after {
            position: static !important;
        }
        .bul_cri {
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #2479dd;
            position: absolute;
            left: 0;
            top: 16px;
        }
        // 表格显示内容  数据显示部分
        .avatar_table {
            margin-bottom: 20px;
            // 下拉框  添加  删除按钮部分
            .out_sel_btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // 下拉框部分
                .sel_inp {
                    margin-top: 15px;
                    display: flex;
                    .el-input {
                        width: 155px;
                        height: 30px;
                        ::v-deep.el-input__inner {
                            width: 155px;
                            height: 30px;
                        }
                    }
                }
                // 添加  删除按钮部分
                .add_del_btn {
                    text-align: right;
                    .el-button {
                        width: 80px;
                        height: 30px;
                        line-height: 4px;
                    }
                    // .addBtn {
                    //     margin-right: 8px;
                    // }
                }
            }
            // 表格内容
            .table_init {
                margin-top: 20px;
                text-align: center;
                // 操作按钮
                span {
                    border: none;
                    width: 24px;
                    height: 18px;
                    font-size: 12px;
                    font-weight: 400;
                    cursor: pointer;
                }
            }
        }
        // 统计图，数据展示区域
        // 日期  下拉框区域
        .date_sel {
            background: #ffffff;
            border-radius: 4px 4px 0 0;
            text-align: left;
            padding: 20px;
            // 日期部分
            .dateDiv {
                margin-bottom: 26px;
                span {
                    font-size: 14px;
                    font-weight: 400;
                    margin-right: 32px;
                }
                .el-input {
                    width: 180px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 180px;
                    height: 30px;
                }
            }
            // 下拉框部分
            .selDiv {
                margin-bottom: 18px;
                span {
                    font-size: 14px;
                    font-weight: 400;
                    margin-right: 32px;
                }
                .el-input {
                    width: 300px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 300px;
                    height: 30px;
                }
            }
        }
        // 图形统计图区域
        .photoDiv {
            background: #ffffff;
            border-radius: 0 0 4px 4px;
            padding: 20px;
        }
    }
    // 弹框部分
    .el-dialog {
        text-align: center;
        // border-radius: 12px;
        // 标题部分
        .tipes {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            text-align: left;
            position: absolute;
            left: 30px;
            top: 30px;
        }
        //  添加  编辑 表单部分
        .el-form {
            text-align: left;
            margin: 30px 0 50px 100px;
            .el-form-item {
                .el-input {
                    width: 472px;
                    height: 30px;
                }
                ::v-deep.el-input__inner {
                    width: 472px;
                    height: 30px;
                }
            }
        }
        // 删除弹框
        .del_content {
            font-size: 18px;
            font-weight: 400;
            margin: 68px 0 80px;
        }
        // 提交  重置  按钮部分
        .formBtn {
            margin-bottom: 20px;
            .del_tips {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 12px;
                color: #d61c1c;
            }
            .el-button {
                width: 98px;
                height: 40px;
                font-size: 14px;
                font-weight: 400;
            }
            // 确认按钮  添加  编辑
            .add_confirm {
                margin-right: 16px;
            }
            // 确认按钮  删除
            .del_confirm {
                margin-right: 16px;
            }
        }
    }
}
</style>